<template>
  <div class="mailbox">
    <div class="bankbox">
      <img src="@/assets/images/new/back-icon.png" alt="" @click="bankgo" />
      <p class="bankname">{{ item.class_name }}</p>
    </div>
    <div class="frominfo">
      <div class="type">
        来自
        <span class="typetext">{{ item.type_name }}</span>
      </div>
      <div class="time">{{ item.create_time }}</div>
    </div>
    <div class="content">
      {{ item.message }}
    </div>
    <div class="btnbox">
      <div class="btn" @click="deletemg">删除邮件</div>
    </div>
  </div>
</template>

<script>
import { delMsgByIdsApi } from "@/network/api";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async deletemg() {
      let params = {
        ids: this.item.id,
      };
      const res = await delMsgByIdsApi(params);
      if (res.data.code == 200) {
        this.$message.success("删除成功");
      }
      this.$emit("bankclick", true);
    },
    bankgo() {
      this.$emit("bankclick", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.mailbox {
  background: #020715;
  min-height: 8rem;
  padding-bottom: 0.2rem;
  .bankbox {
    padding: 0 0.2rem;
    width: 100%;
    height: 0.44rem;
    display: flex;
    align-items: center;
    background: #020715;
    text-align: center;
    position: relative;
    img {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
    }
    .bankname {
      width: 100%;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.16rem;
      color: #ffffff;
    }
  }
  .frominfo {
    width: 3.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 0.14rem;
    color: #b0b0e7;
  }
  .content {
    width: 3.35rem;
    min-height: 70vh;
    margin: 0.16rem auto;
    font-weight: 400;
    font-size: 0.14rem;
    color: #ffffff;
  }
  .btnbox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 1.43rem;
      height: 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(95deg, #2f2f5e 0%, #222242 100%);
      font-weight: 500;
      font-size: 0.14rem;
      color: #ffffff;
      border: 0.02rem solid #6565a6;
    }
  }
}
</style>
