<template>
  <div>
    <div class="mailbox" v-show="!isdetail">
      <div class="bankbox">
        <img
          src="../../assets/images/new/back-icon.png"
          alt=""
          @click="bankgo" />
        <p class="bankname">邮件中心</p>
      </div>
      <div class="mailcenter">
        <div class="checkbox">
          <div class="selectbox">
            <div class="select-box point" @click="isShowFloat = !isShowFloat">
              <div class="input-value flex-s">
                {{ parseSelectList.find((item) => item.value == value)?.label }}
              </div>
              <div class="row">
                <img
                  src="../../assets/images/newuser/mb-rechange-pullicon.png"
                  alt="" />
              </div>
              <div v-show="isShowFloat" class="float-box">
                <div
                  @click="changeSelect(item)"
                  class="float-box-item flex-s"
                  v-for="(item, index) in parseSelectList"
                  :key="index">
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="OneClick">
            <div v-if="value && !deleteshow" class="onebox" @click="allread">
              <img src="../../assets/images/Rank/read.png" alt="" />一键已读
            </div>
            <!-- <div class="onebox" @click="deleteAll">
              <img src="../../assets/images/Rank/delete.png" alt="" />一键删除
            </div> -->
            <div v-if="deleteshow" @click="deleteDoneOnClick" class="onebox">
              <img src="../../assets/images/Rank/wancheng.png" alt="" />确认删除
            </div>
            <div v-if="!deleteshow" class="onebox" @click="deleteclick">
              <img src="../../assets/images/Rank/delete.png" alt="" />批量删除
            </div>
          </div>
        </div>
        <div class="listbox" id="mailListId" @scroll="scroll">
          <div class="listitem" v-for="item in msgList" :key="item.id">
            <div
              class="clickbox"
              v-show="deleteshow && item.is_read !== 1"
              @click="tocheck(item)">
              <img
                src="../../assets/images/Rank/click.png"
                alt=""
                v-show="chekindex.includes(item)" />
              <img
                src="../../assets/images/Rank/noclick.png"
                alt=""
                v-show="!chekindex.includes(item)" />
            </div>
            <div class="left-box flex-c">
              <div class="icon">
                <img
                  class="w-100 h-100"
                  :src="
                    require(`../../assets/images/Rank/mailicon${item.class}.png`)
                  "
                  alt="" />
                <div
                  class="tipsicon"
                  v-show="item.is_read == 1 && !deleteshow"></div>
              </div>
            </div>
            <div class="center-box" @click="godetail(item)">
              <div class="title">{{ item.class_name }}</div>
              <div class="content elips1">{{ item.message }}</div>
            </div>
            <div class="right-box">
              <div class="time">{{ item.create_time }}</div>
            </div>
            <div class="del-btn-box flex-c" @click="deleteone(item)">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                ">
                <img src="../../assets/images/Rank/close.png" alt="" /> 删除
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isdetail">
      <Maildetail :item="detailitem" @bankclick="banktype" />
    </div>
  </div>
</template>

<script>
import {
  getMsgTypeListApi,
  getMsgListApi,
  delMsgByIdsApi,
  readMsgApi,
  readAllMsgApi,
} from "../../network/api";
import Maildetail from "./components/Maildetail.vue";
export default {
  data() {
    return {
      isShowFloat: false, //下拉状态
      msgTypeList: [],
      msgList: [],
      value: 0,
      deleteshow: false,
      chekindex: [],
      pageIndex: 1,
      isdetail: false,
      detailitem: {},
      isScrollFetch: false, // 用户是否已经滑动需要请求后续数据
    };
  },
  components: { Maildetail },
  created() {
    this.init();
  },
  watch: {
    isScrollFetch(newVal) {
      if (newVal) {
        this.pageIndex += 1;
        this.fetchData();
      }
    },
  },
  methods: {
    scroll(e) {
      // 获取 div 元素
      const myDiv = document.getElementById("mailListId");
      // 获取垂直滚动距离
      const scrollTop = myDiv.scrollTop;
      if (scrollTop > 500) {
        this.isScrollFetch = true;
      }
    },
    async deleteAll() {
      if (this.msgList.length === 0) {
        this.$message.warning("没有邮件可以删除");
        return;
      }

      const idsToDelete = this.msgList.map((item) => item.id);
      const params = {
        ids: idsToDelete.join(),
      };

      const res = await delMsgByIdsApi(params);
      if (res.data.code === 200) {
        this.$message.success("全部邮件已删除");
        this.refreshData(); // 刷新数据
      } else {
        this.$message.error("删除失败，请稍后再试");
      }
    },
    deleteone(v) {
      let params = {
        ids: v.id,
      };
      delMsgByIdsApi(params).then((res) => {
        if (res.data?.code === 200) {
          this.$toast("删除成功");
          this.refreshData();
        }
      });
    },
    async allread() {
      let params = {
        class: this.value,
      };
      const res = await readAllMsgApi(params);
      if (res.data.code == 200) {
        this.$message.success("阅读成功");
      }
      this.init();
    },
    banktype(v) {
      this.isdetail = !v;
      this.init();
    },
    godetail(v) {
      this.detailitem = v;
      this.isdetail = true;
      let params = {
        id: v.id,
      };
      readMsgApi(params).then(() => {});
      this.deleteshow = false;
    },
    init() {
      this.getMsgTypeListFun();
      this.refreshData();
    },
    async getMsgTypeListFun() {
      const res = await getMsgTypeListApi();
      if (res.data?.code === 200) {
        this.msgTypeList = res.data.data.class;
      }
    },
    async refreshData() {
      this.pageIndex = 1;
      this.msgList = [];
      this.fetchData();
    },
    async fetchData() {
      try {
        const params = {
          page: this.pageIndex,
        };
        if (this.value) {
          params.class = this.value;
        }
        const res = await getMsgListApi(params);
        if (res.data?.code === 200) {
          this.msgList = [...this.msgList, ...res.data.data.list];
          if (res.data.data.list.length === 20) {
            if (this.isScrollFetch) {
              this.pageIndex += 1;
              await this.fetchData();
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    tocheck(v) {
      const index = this.chekindex.indexOf(v);
      if (index > -1) {
        this.chekindex.splice(index, 1);
      } else {
        this.chekindex.push(v);
      }
    },
    deleteclick() {
      this.deleteshow = !this.deleteshow;
    },
    deleteDoneOnClick() {
      const ids = this.chekindex.map((item) => item.id).join(",");
      delMsgByIdsApi({ ids }).then((res) => {
        if (res.data?.code === 200) {
          this.$toast("删除成功");
          this.chekindex = [];
          this.refreshData();
        }
      });
      this.deleteshow = false;
    },
    bankgo() {
      this.$router.back();
    },
    changeSelect(v) {
      this.value = v.value;
      this.isScrollFetch = false;
      this.deleteshow = false;
      this.pageIndex = 1;
      this.refreshData();
    },
  },
  computed: {
    parseSelectList() {
      const array = this.msgTypeList.map((item) => {
        return {
          label: item?.title || item,
          value: item?.id || item,
        };
      });
      array.unshift({ label: "全部", value: 0 });
      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
.mailbox {
  background: #020715;
  min-height: 8rem;
  padding-bottom: 0.2rem;
  .bankbox {
    padding: 0 0.2rem;
    width: 100%;
    height: 0.44rem;
    display: flex;
    align-items: center;
    background: #020715;
    text-align: center;
    position: relative;
    img {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
    }
    .bankname {
      width: 100%;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.16rem;
      color: #ffffff;
    }
  }
  .mailcenter {
    width: 3.5rem;
    margin: 0 auto;
    .checkbox {
      margin: 0.1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .selectbox {
        .select-box {
          width: 0.89rem;
          height: 0.24rem;
          background: #08080f;
          border: 0.01rem solid #2f2f5e;
          position: relative;
          .input-value {
            width: 100%;
            height: 100%;
            line-height: 0.24rem;
            padding-left: 0.05rem;
            box-sizing: border-box;
            font-size: 0.1rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
            overflow: hidden;
            white-space: nowrap;
          }
          .row {
            position: absolute;
            top: 0.02rem;
            right: 0.18rem;
            z-index: 1;
            img {
              width: 0.17rem;
              height: 0.16rem;
            }
          }

          .float-box {
            position: absolute;
            left: -0.01rem;
            background: #08080f;
            z-index: 99;
            border: 0.01rem solid #2f2f5e;
            border-top: 0;
            .float-box-item {
              width: 0.87rem;
              height: 0.24rem;
              line-height: 0.24rem;
              padding: 0 0.05rem;
              box-sizing: border-box;
              font-size: 0.1rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              color: #c5bbed;
              white-space: nowrap;
              overflow-x: hidden;
            }
            .float-box-item:hover {
              background: #2f2f5e;
              color: #c5bbed;
            }
          }
        }
      }
      .OneClick {
        // width: rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .onebox {
          // width: 0.68rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 0.15rem;
          font-weight: 400;
          font-size: 0.12rem;
          color: #c5bbed;
          margin-left: 0.12rem;
          cursor: pointer;
          img {
            width: 0.14rem;
            height: 0.14rem;
            margin-right: 0.04rem;
          }
        }
      }
    }
    .listbox {
      width: 100%;
      overflow-y: auto;
      height: calc(100vh - 1.8rem);
      .listitem {
        width: 100%;
        padding: 0.14rem 0.12rem;
        box-sizing: border-box;
        border-radius: 0.02rem;
        background: #101021;
        margin-bottom: 0.12rem;
        display: flex;
        align-items: center;
        position: relative;
        overflow: scroll;
        align-items: flex-start;
        .clickbox {
          width: 0.42rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
          img {
            width: 0.16rem;
            height: 0.16rem;
          }
        }
        .left-box {
          width: 0.44rem;
          height: 0.44rem;
          border-radius: 50%;
          border: 0.01rem solid #302d65;
          margin-right: 0.1rem;
          flex-shrink: 0;
          position: relative;
          .icon {
            width: 0.26rem;
            height: 0.26rem;
          }
          .tipsicon {
            position: absolute;
            width: 0.08rem;
            height: 0.08rem;
            background: #ff7300;
            border-radius: 50%;
            top: 0;
            right: 0;
          }
        }
        .center-box {
          display: flex;
          align-content: space-between;
          flex-wrap: wrap;
          // margin-right: 0.4rem;
          .title {
            width: 100%;
            font-size: 0.14rem;
            color: #ffffff;
            margin-bottom: 0.12rem;
          }
          .content {
            width: 100%;
            font-size: 0.12rem;
            color: #ffffff;
            opacity: 0.7;
            display: block;
            max-width: 1.3rem;
          }
        }
        .right-box {
          font-size: 0.1rem;
          color: #ffffff;
          opacity: 0.6;
          .time {
            white-space: nowrap;
          }
        }
        .del-btn-box {
          width: 0.64rem;
          height: 0.73rem;
          position: absolute;
          right: -0.64rem;
          top: 0;
          background: #ee4b4b;
          font-weight: 500;
          font-size: 0.12rem;
          text-align: center;
          color: #ffffff;
          img {
            width: 0.16rem;
          }
        }
      }
    }
  }
}
</style>
